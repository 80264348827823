import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import jwt from 'jsonwebtoken'
import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'
import 'vuetify/dist/vuetify.min.css' // FIXME : A quoi ca sert ?
// import '@mdi/font/css/materialdesignicons.css' // FIXME : ajout de police depuis materialdesign ?
// import '@/assets/sass/perseus-2.scss' // FIXME : DOES NOT WORK !
// import '@/style/sass/perseus-2.scss' // NB : cet import est inclu dans /plugin/perseus-2
// import Perseus2 from './plugins/perseus2';
// Vue.use(Perseus2);

sync(store, router)

Vue.config.productionTip = true
Vue.use(VueCookies)
VueCookies.config('12h')
// set global cookie
VueCookies.set('theme', 'default')
VueCookies.set('hover-time', '1s')

/* export default function ({ $axios, store }) {
  const agent = new https.Agent({
    rejectUnauthorized: false,
    Authorization: store.state.token,
  })
  $axios.onRequest(config => {
    if (process.env.dev) {
      config.httpsAgent = agent
    }
  })
}
*/
const key = process.env.VUE_APP_NOT_SECRET_CODE
const tokenTmp = jwt.sign({
  uid: 'VISITEUR',
  iat: Math.round(Date.now() / 1000),
  exp: Math.round(Date.now() / 1000) + 90 * 60,
},
key)
if (!axios.defaults.headers.common.token) {
  axios.defaults.headers.common.token = tokenTmp
}
axios.defaults.baseURL = 'https://perseus.univ-grenoble-alpes.fr/api/v1' // marche pour agalan
// axios.defaults.baseURL = 'https://gricad-ldap.u-ga.fr:5055' // marche pour agalan

new Vue({
  data: {
    key,
  },
  router,
  store,
  axios,
  vuetify,
  render: h => h(App),
}).$mount('#app')

export default key
