import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7bcad8', // glightblue
        secondary: '#7BCAD8', // gricad.blue
        tertiary: '#073C5D', // gricad.darkblue
        accent: '#82B1FF',
        error: '#E83E39', // gricad.red
        info: '#00d3ee',
        success: '#5cb860',
        warning: '#ffa21a',
        blue: '#58b8f3',
        glightblue: '#7bcad8',
        gmiddleblue: '#3ea7bf',
        gmiddleblue2: '#246a79',
        gdarkblue: '#073c5d',
        gred: '#eb4642',
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
})
