<template>
  <v-app>
    <core-app-bar />
    <core-footer />
    <core-drawer />
    <core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    components: {
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreFooter: () => import('@/components/core/Footer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreView: () => import('@/components/core/View'),
    },

    created () {
      this.$store.state.windowW = document.documentElement.clientWidth
      this.$store.state.windowH = document.documentElement.clientHeight
    },
  }
</script>

<style lang="scss">
  //@import "./assets/scss/_variables.scss";
  //@import 'src/assets/scss/cards/_card-chart.scss';

  $ff: Arial, Helvetica, sans-serif;
  $ff1: Verdana, Geneva, Tahoma, sans-serif;
  $ff2: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  $ff3: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
    Arial, sans-serif;
  $ff4: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;

  #app {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
</style>
