import Vue from 'vue'
import Offset from '@/components/helper/Offset'
import Card from '@/components/material/Card'
import ChartCard from '@/components/material/ChartCard'
import Notification from '@/components/material/Notification'
import StatsCard from '@/components/material/StatsCard'

import BarChart from '@/components/Charts/BarChart'
import PieChart from '@/components/Charts/PieChart'
import LineChart from '@/components/Charts/LineChart'

Vue.component(Offset.name, Offset)
Vue.component(Card.name, Card)
Vue.component(ChartCard.name, ChartCard)
Vue.component(Notification.name, Notification)
Vue.component(StatsCard.name, StatsCard)

Vue.component(BarChart.name, BarChart)
Vue.component(PieChart.name, PieChart)
Vue.component(LineChart.name, LineChart)
