import axios from 'axios'
import store from './index'
import jwt from 'jsonwebtoken'
const key = process.env.VUE_APP_NOT_SECRET_CODE //'SecretKeySecret'

function checkToken (token) {
    var validToken = false
        if (token != null) {
            var data = jwt.decode(token)
            const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
            const now = new Date()
            validToken = now < exp && Boolean(jwt.verify(token, key))
        }
    return validToken
}

export default checkToken
