/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/',
    // Relative to /src/views
    view: 'Dashboard',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    view: 'Dashboard',
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    view: 'UserProfile',
  },
  {
    path: '/table-list',
    name: 'Table List',
    view: 'TableList',
  },
  {
    path: '/my-mailing-list',
    name: 'My Mailing List',
    view: 'MyMailingList',
  },
  {
    path: '/my-projects',
    name: 'My projects',
    view: 'MyProjects',
    children: [ // FIXME : les pages enfants ne fonctionnent pas
      {
        path: '/:project',
        view: 'Project',
      },
      { // FIXME : les pages enfants ne fonctionnent pas
        path: '/join-project',
        name: 'Join a project',
        view: 'MyProjects/JoinProject',
      },
      { // FIXME : les pages enfants ne fonctionnent pas
        path: '/create-project',
        name: 'Create a project',
        view: '/MyProjects/CreateProject',
      },
    ],
  },

  {
    path: '/my-projects/project/:project',
    view: 'Project',
  },

  {
    path: '/my-projects/join-project',
    name: 'Join a project',
    view: 'MyProjects/JoinProject',
  },
  {
    path: '/my-projects/create-project',
    name: 'Create a project',
    view: 'MyProjects/CreateProject',
  },
  /*
  {
    path: '/my-projects/create-test',
    name: 'Test for create',
    view: 'MyProjects/TestCreateProject',
  },
  */

  // Les phases de CreateAccount
  {
    path: '/create-account/portal',
    name: 'New account - portal choice',
    view: 'CreateAccount/CreateAccountPortal',
  },
  {
    path: '/create-account/portal-agalan',
    name: 'New account - portal agalan',
    view: 'CreateAccount/CreateAccountPortalAgalan',
  },
  {
    path: '/create-account/form',
    name: 'New account - input form',
    view: 'CreateAccount/CreateAccountForm',
  },
  {
    path: '/create-account/check',
    name: 'New account - check phase',
    view: 'CreateAccount/CreateAccountCheck',
  },
  {
    path: '/create-account/end',
    name: 'New account - end',
    view: 'CreateAccount/CreateAccountEnd',
  },
  {
    path: '/migrate-account/check',
    name: 'Migrate account - check infromation',
    view: 'CreateAccount/MigrateAccountCheck',
  },

  // Les pages d'erreur
  {
    path: '/error/notfound',
    name: 'Not found error',
    view: 'Error/NotFound',
  },
  {
    path: '/error/coming-soon',
    name: 'Coming soon',
    view: 'Error/ComingSoon',
  },
  {
    path: '/error/nasty',
    name: 'Nasty error',
    view: 'Error/Nasty',
  },
  {
    path: '/error/ldaperror',
    name: 'LDAP error',
    view: 'Error/LdapError',
  },

  {
    path: '/my-accounting',
    name: 'My accounting',
    view: 'MyAccounting',
  },
  {
    path: '/bibliography',
    name: 'Bibliography',
    view: 'Bibliography',
  },
  {
    path: '/accounting',
    name: 'Accounting',
    view: 'Accounting',
  },
  {
    path: '/faq',
    name: 'Frequently Asked Questions',
    view: 'FAQ',
  },
  {
    path: '/contact',
    name: 'Contact',
    view: 'Contact',
  },
  {
    path: '/review',
    name: 'Review',
    view: 'Review',
  },
  {
    path: '/user-list',
    name: 'User List',
    view: 'UserList',
  },
  {
    path: '/project-list',
    name: 'Project List',
    view: 'ProjectList',
  },
  {
    path: '/login',
    name: 'test',
    view: 'test',
  },
  {
    path: '/first-connection',
    name: 'First Connection',
    view: 'FirstConnection',
  },
  {
    path: '/change-password',
    name: 'Change password',
    view: 'ChangePassword',
  },
  ]
