import axios from 'axios'
import store from '.'
import jwt from 'jsonwebtoken'
import checkToken from '../store/checkToken'
import cookies from 'vue-cookies'
import { CommentEntry } from 'bibtex'

function formatDate (date) {
    var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()

    if (month.length < 2) { month = '0' + month }
    if (day.length < 2) { day = '0' + day }

    return [day, month, year].join('/')
  }
function updateData () {
  var token = null
  token = cookies.get('token')
  var authtoken = null
  authtoken = cookies.get('authtoken')
  axios.defaults.headers.common.authtoken = authtoken
  var validToken = checkToken(token)
  var data = jwt.decode(token)
  if (validToken && token != null) {
    store.state.admin = data.admin
    store.state.reviewer = data.reviewer
    store.state.permanent = data.permanent
    store.state.extern = data.extern
  }
  if (!store.state.updated && token != null && validToken) {
    axios.defaults.headers.common.token = token
    axios
      .get('/username/' + data.uid)
      .then(response => {
        var account = response.data
        store.state.account.login = account.uid[0]
        store.state.account.name = account.sn[0]
        store.state.account.firstname = account.givenName[0]
        store.state.account.mail = account.mail[0]
        if (account.emailKey) {
          store.state.account.emailKey = account.emailKey[0]
        }
        if(account.employeeType) {
          store.state.account.contracttype = account.employeeType
        }
        if (account.shadowExpire == null) {
          store.state.account.infos.user_status.value = 'Pending'
        } else {
          axios
        .get('/username/' + data.uid + '/get-status')
        .then(response => {
          store.state.account.infos.user_status.value = response.data
        })
        axios
        .get('/username/' + data.uid + '/check-isAgalan')
        .then(response => {
          store.state.account.isAgalan = response.data
        })
          store.state.account.infos.expire_date.value = formatDate(account.shadowExpire[0] * 24 * 3600 * 1000)
          var dateNow = Date.now() / 1000 // c'est en secondes
          var daysRemains = account.shadowExpire[0] - Math.floor(dateNow / (24 * 3600))
          if (account.shadowExpire[0] <= 30 || daysRemains <= 60) {
            store.state.alertExpire = true
            store.state.expiration = daysRemains
          }
        }
        if (Number(account.contractDate[0]) === 0) {
          store.state.account.infos.contract_date.value = 'Permanent'
        } else {
          store.state.account.infos.contract_date.value = formatDate(account.contractDate[0] * 1000)
        }
        store.state.account.infos.charter_date.value = formatDate(account.charterDate[0] * 1000)
      })
      axios.get('/username/' + data.uid + '/getall-projects4user').then(response => {
          var List = response.data
          if (List !== null) {
            for (let i = 0; i < List.length; i++) {
              axios.get('/get-project/' + List[i])
                .then(response => {
                  var project = response.data
                  axios.get('/project/' + List[i] + '/get-projectStatus')
                    .then(response => {
                      var tmpStatus = response.data
                      // this.$store.state.polesProjects.projects[String(List[i])].status = response.data
                      axios.get('/project/' + List[i] + '/get-lab')
                        .then(response => {
                          var tmpLaboratory = response.data.slice(2)
                          if (store.state.joinedProjects.projects.length === 0) {
                            store.state.joinedProjects.projects = [{
                              name: project.cn[0],
                              description: project.description[0],
                              status: tmpStatus,
                              laboratory: tmpLaboratory,
                              wiki: 'https://perseus-wiki.univ-grenoble-alpes.fr/en/' + project.cn[0] + '/Description',
                              accounting: 'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=' + project.cn[0].substring(3) + '&var-User=All'
                            }]
                          } else {
                            store.state.joinedProjects.projects.push({
                              name: project.cn[0],
                              description: project.description[0],
                              status: tmpStatus,
                              laboratory: tmpLaboratory,
                              wiki: 'https://perseus-wiki.univ-grenoble-alpes.fr/en/' + project.cn[0] + '/Description',
                              accounting: 'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=' + project.cn[0].substring(3) + '&var-User=All'
                            })
                          }
                        })
                    })
                })
            }
        }
        })
        if (store.state.permanent) {
          axios.get('/username/' + data.uid + '/getall-projects4userAdmin').then(response => {
              var List = response.data
              if (List !== null) {
              for (let i = 0; i < List.length; i++) {
                axios.get('/get-project/' + List[i])
                  .then(response => {
                    var project = response.data
                    axios.get('/project/' + List[i] + '/get-projectStatus')
                      .then(response => {
                        var tmpStatus = response.data
                        // this.$store.state.polesProjects.projects[String(List[i])].status = response.data
                        axios.get('/project/' + List[i] + '/get-lab')
                          .then(response => {
                            var tmpLaboratory = response.data.slice(2)
                            if (store.state.createdProjects.projects.length === 0) {
                              store.state.createdProjects.projects = [{
                                name: project.cn[0],
                                description: project.description[0],
                                status: tmpStatus,
                                laboratory: tmpLaboratory,
                                wiki: 'https://perseus-wiki.univ-grenoble-alpes.fr/en/' + project.cn[0] + '/Description',
                                accounting: 'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=' + project.cn[0].substring(3) + '&var-User=All'
                              }]
                            } else {
                              store.state.createdProjects.projects.push({
                                name: project.cn[0],
                                description: project.description[0],
                                status: tmpStatus,
                                laboratory: tmpLaboratory,
                                wiki: 'https://perseus-wiki.univ-grenoble-alpes.fr/en/' + project.cn[0] + '/Description',
                                accounting: 'https://gricad-dashboards.univ-grenoble-alpes.fr/d/8nXIw5XMz/oar-clusters-project-stats?orgId=1&var-Cluster=All&var-Queue=besteffort&var-Queue=default&var-Project=' + project.cn[0].substring(3) + '&var-User=All'
                              })
                            }
                          })
                      })
                  })
              }
            }
            })
      }

    axios
      .get('/username/' + data.uid + '/getall-groups4user-ofType/m-*')
      .then(response => {
        store.state.listMachine = response.data
      })
    axios
      .get('/username/' + data.uid + '/getall-groups4user-ofType/p-*')
      .then(response => {
        store.state.listPole = response.data
        if (response.data) {
            axios
            .get('/get-poleLeader-cn&mail/' + response.data[0])
            .then(response => {
              store.state.listPoleLeader = response.data
            })
        } else {
          store.state.listPoleLeader = []
        }
        })
    axios
      .get('/username/' + data.uid + '/getall-groups4user-ofType/l-*')
      .then(response => {
        store.state.listLab = response.data
      })
    axios
      .get('/username/' + data.uid + '/getall-groups4user-ofType/f-*')
      .then(response => {
        store.state.listFunctionalGroup = response.data
      })
      axios.get('/getall-mailingList').then(response => {
        var List = response.data
        axios
        .get('/username/' + data.uid + '/getall-groups4user-ofType/li-*')
        .then(response => {
          store.state.listMailingList = response.data

        List.forEach(function (i) {
            axios
            .get('/get-mailingList/' + i)
            .then(response => {
                var localList = response.data
                if (store.state.listMailingList.includes(i)) {
                  store.state.mailingList.push({
                    title: localList.cn[0],
                    description: localList.description[0],
                    sub: true,
                    })
                } else {
                  store.state.mailingList.push({
                    title: localList.cn[0],
                    description: localList.description[0],
                    sub: false,
                    })
                }
            })
          })
        })
        })
        if (store.state.admin || store.state.reviewer) {
          axios.get('/getall-pendingProjects').then(response => {
            var pendingProjects = response.data
            if (pendingProjects !== null) {
              for (let j = 0; j < pendingProjects.length; j++) {
              axios
              .get('/project/' + pendingProjects[j])
              .then(response => {
                var localProject = response.data
                var localLaboratory = ''
                var localBegin = ''
                var localEnd = ''
                var localReview = ''
                var localCpuTime = ''
                var localAdmins = []
                var localMembers = []
                var localScientificDescription = ''
                var localScientificReview = false
                var localTechnicalDescription = ''
                var localTechnicalReview = false
                var localAdditionalInfos = ''
                var localProjectComments = []
                var localReject = false
                var localReasonReject = ''
                var localCommentWaiting = ''
                localBegin = formatDate(Number(localProject.creationDate[0] * 1000))
                if (localProject.validityDate != null) {
                  localEnd = formatDate(Number(localProject.validityDate[0] * 1000))
                }
                if (localProject.checkDate != null) {
                  localReview = formatDate(Number(localProject.checkDate[0] * 1000))
                }
                if (localProject.estimatedCpuTime != null) {
                  localCpuTime = localProject.estimatedCpuTime[0]
                }
                localProject.adminUid.forEach(admin => {
                  axios
                    .get('/username/' + admin + '/get-cn')
                    .then(response => {
                      localAdmins.push({
                        uid: admin,
                        cn: response.data,
                      })
                    })
                })
                if (localProject.memberUid != null) {
                  localProject.memberUid.forEach(member => {
                    axios
                      .get('/username/' + member + '/get-cn')
                      .then(response => {
                        localMembers.push({
                          uid: member,
                          cn: response.data,
                        })
                      })
                  })
                }
                localScientificDescription = b64DecodeUnicode(localProject.scientificDescription[0])
                localTechnicalDescription = b64DecodeUnicode(localProject.technicalDescription[0])
                if (localProject.miscDescription != null) {
                  localAdditionalInfos = b64DecodeUnicode(localProject.miscDescription[0]).split('\n')
                }
                if (localProject.perseusData != null) {
                  var perseusData = JSON.parse(localProject.perseusData)
                  var localComments = perseusData.comments
                  if (localComments != null) {
                    if (localComments.length == null) {
                      localProjectComments.push({
                        username: localComments.username,
                        date: localComments.date,
                        comment: b64DecodeUnicode(localComments.comment),
                      })
                    } else {
                    for (var index = 0; index < localComments.length; index++) {
                      var comment = localComments[index]
                      localProjectComments.push({
                        username: comment.username,
                        date: comment.date,
                        comment: b64DecodeUnicode(comment.comment),
                      })
                    }
                  }
                    /* localComments.forEach(comment => {
                      store.state.projectsToReview.projects[pendingProjects[j]].comments.push({
                        username: comment.username,
                        date: comment.date,
                        comment: b64DecodeUnicode(comment.comment),
                      })
                    }) */
                  }
                  var scientificReview = perseusData.scientific_review
                  var technicalReview = perseusData.technical_review

                  if (scientificReview === 'true') {
                    localScientificReview = true
                  } else {
                    localScientificReview = false
                  }
                  if (technicalReview === 'true') {
                    localTechnicalReview = true
                  } else {
                    localTechnicalReview = false
                  }
                }
                axios
                  .get('/project/' + pendingProjects[j] + '/get-lab')
                  .then(response => {
                    if (response.data) {
                      localLaboratory = response.data.slice(2)
                    } else {
                      localLaboratory = 'other'
                    }
                    var bool = false
                  if (localTechnicalReview && localScientificReview) {
                    bool = true
                  }
                  axios
                  .get('/project/' + pendingProjects[j] + '/get-reviewer')
                  .then(response => {
                    var localreviewers = response.data
                if (store.state.projectsToReview.projects.length === 0) {
                store.state.projectsToReview.projects = [{
                                name: pendingProjects[j],
                                description: localProject.description[0],
                                laboratory: localLaboratory,
                                begin: localBegin,
                                end: localEnd,
                                review: localReview,
                                cpuTime: localCpuTime,
                                admins: localAdmins,
                                members: localMembers,
                                scientificDescription: localScientificDescription,
                                scientificReview: localScientificReview,
                                technicalDescription: localTechnicalDescription,
                                technicalReview: localTechnicalReview,
                                additionalInfos: localAdditionalInfos,
                                comments: localProjectComments,
                                reject: localReject,
                                reasonReject: localReasonReject,
                                commentWaiting: localCommentWaiting,
                                acceptBool: bool,
                                reviewers: localreviewers
                              }]
              } else {
                store.state.projectsToReview.projects.push({
                  name: pendingProjects[j],
                  description: localProject.description[0],
                  laboratory: localLaboratory,
                  begin: localBegin,
                  end: localEnd,
                  review: localReview,
                  cpuTime: localCpuTime,
                  admins: localAdmins,
                  members: localMembers,
                  scientificDescription: localScientificDescription,
                  scientificReview: localScientificReview,
                  technicalDescription: localTechnicalDescription,
                  technicalReview: localTechnicalReview,
                  additionalInfos: localAdditionalInfos,
                  comments: localProjectComments,
                  reject: localReject,
                  reasonReject: localReasonReject,
                  commentWaiting: localCommentWaiting,
                  acceptBool: bool,
                  reviewers: localreviewers
                })
                }
              })
              })
              })
          }
        }
      })
      }

axios.get('/getall-poles').then(response => {
  var List = response.data
  List.forEach(function (pole) {
    store.state.listAllPoles.push(pole)
  })
})

axios.get('/getall-labs').then(response => {
  var List = response.data
  List.forEach(function (lab) {
    store.state.listAllLabs.push(lab)
  })
})


      if (store.state.extern) {
        axios
        .get('/username/' + data.uid + '/get-referent')
        .then(response => {
          store.state.listReferent = response.data
        })
      }
    store.state.updated = true
}
}

function b64DecodeUnicode (str) {
  return decodeURIComponent(escape(window.atob( str )));
}
export default updateData
