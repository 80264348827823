/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '../store'
// Routes
import paths from './paths'
import resetStore from '../store/resetStore'
import checkToken from '../store/checkToken'
import updateData from '../store/updateData'
Vue.use(store)

function route (path, view, name) {
  return {
    name: name || view,
    path,
    component: (resovle) => import(
      `@/views/${view}.vue`
    ).then(resovle),
  }
}

Vue.use(Router)
function sleep (milliseconds) {
  var start = new Date().getTime()
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds) {
      break
    }
  }
}

// Create a new router
var router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path.path, path.view, path.name)).concat([
    { path: '*', redirect: '/' },
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

Vue.use(Meta)
var nextUrl = null

router.beforeEach(async function (to, from, next) {
  var authRequired = (to.name !== 'test' && !to.fullPath.includes('create-account') && !to.fullPath.includes('error'))
  var token = $cookies.get('token')
  var validToken = checkToken(token)
  if(to.fullPath.includes('wiki-review')) {
    return next()
  }
    if (!validToken && !to.fullPath.includes('create-account')) {
      resetStore()
    }
    if (validToken) {
      updateData()
        return next()
    }
    if (authRequired && (store.state.token === null || !validToken)) {
      nextUrl = to.fullPath
      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
  } else {
      return next()
  }

})

export default router
