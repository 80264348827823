/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
import jwt from 'jsonwebtoken'
// Store functionality
import modules from './modules'
import route from '@/router'
const key = process.env.VUE_APP_NOT_SECRET_CODE // 'SecretKeySecret'

Vue.use(Vuex)
// Create a new store
const store = new Vuex.Store({
  modules,
  state: {
    account: {
      login: '',
      name: '',
      firstname: '',
      mail: '',
      role: ['user'],
      emailKey: '',
      contracttype: '',
      isAgalan: null,
      infos: {
        user_status: {
          name: 'Cluster acces status',
          value: '',
        },
        expire_date: {
          name: 'Expiration date',
          value: '',
        },
        contract_date: {
          name: 'Contract date',
          value: '',
        },
        charter_date: {
          name: 'Charter date',
          value: '',
        },
      },
    },
    windowW: null,
    windowH: null,
    drawer: false,
    showDrawerBtn: false,

    // Booleens de status
    admin: false,
    reviewer: false,
    permanent: false,
    extern: false,
    uid: '',
    token: '',
    headers: {
      Authorization: '',
    },
    role: ['user'],

    // Information de compte : a tirer du ldap
    boolAddMember: false,
    projectsMember: [
      {
        cn: 'pr-admin',
        description: 'Administration Play',
      },
    ],
    allActiveProjects: [],
    allGroups: [],
    projectsAdmin: ['pr-admin', 'pr-funproject'],
    dialog: false,
    darkTheme: true,
    ml_event_change: false,

    IMG_NEBULEUSE_out: 'https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/images/nebuleuse.jpg',
    imgNebuleuse: '@/assets/nebuleuse.jpg',
    colors: {
      card_bg_dark: '#27293d',
      card_bg_light: '',
    },

    projectsToReview: {
      title: 'Projects to review',
      columns: ['name', 'description', 'laboratory', 'technicalreview', 'scientificreview', 'reject'],
      projects: [],
    },

    joinedProjects: {
      title: 'Joined Projects Table',
      columns: ['Name', 'Description', 'Status', 'Laboratory', 'Wiki', 'Accounting'],
      projects: [],
    },

    createdProjects: {
      title: 'Created Projects Table',
      columns: ['Name', 'Description', 'Status', 'Laboratory', 'Wiki', 'Accounting'],
      projects: [],
    },

    mailingList: [ // Recuperer les uid des subscription dans this.$root.data.listMailingList
    ],
    listMachine: [],
    listPole: [],
    listLab: [],
    listFunctionalGroup: [],
    listMailingList: [],
    listReferent: [],
    listPoleLeader: [],

    link: {
      accounting: 'https://perseus-front.univ-grenoble-alpes.fr/error/coming-soon', // 'https://ciment-grid.univ-grenoble-alpes.fr',
      documentation: 'https://gricad.gricad-pages.univ-grenoble-alpes.fr/user_doc/',
    },
    listAllPoles: [],
    listAllLabs: [],
    valuePole: false,
    menu1: 'pole',
    menu2: 'year',
    itemMenu2: [
      'year',
      '2020',
      '2019',
      '2018',
      '2017',
      '2016',
      '2015',
      '2014',
      '2013',
      '2012',
      '2011',
      '2010',
      '2009',
      '2008',
    ],
    valueYear: false,
    dialogCookies: true,
    my_ip: '',
    validToken: false,

    // Formulaire de creation de compte
    registrationForm: {
      login: null,
      firstname: null,
      lastname: null,
      lab: null,
      otherlab: null,
      contracttype: null,
      contractdate: null,
      contractdate_ts: null, // timestamp
      email: null,
      password: null, // sous forme de hash ?
      passwordcheck: null,
      cguboolean: false,
      permanent: false,
      uidNumber: '',
    },
    alertExpire: false,
    expiration: 30,
    updated: false,
    getProjectPole: false,

  },
  mutations: {
    checkToken (state) {
      const token = $cookies.get('token')
      if (token != null) {
        var data = jwt.decode(token)
        const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
        const now = new Date()
        state.validToken = now < exp && (state.my_ip === data.ip) && Boolean(jwt.verify(token, key))
      }
    },
    updateDrawer (state) {
      if (route.currentRoute.path.includes('/create-account') || route.currentRoute.path.includes('/login')) {
        store.state.drawer = false
        store.state.showDrawerBtn = false
      } else {
        store.state.drawer = true
        store.state.showDrawerBtn = true
      }
    },
  },
actions: {
    reset (context) {
      context.commit('reset')
    },
    checkToken (context) {
      context.commit('checkToken')
    },
},
})

export default store
