import axios from 'axios'
import store from '.'
import jwt from 'jsonwebtoken'
const key = process.env.VUE_APP_NOT_SECRET_CODE // 'SecretKeySecret'

async function resetStore () {
    const tokenTmp = jwt.sign({
      uid: 'VISITEUR',
      iat: Math.round(Date.now() / 1000),
      exp: Math.round(Date.now() / 1000) + 90 * 60,
    },
    key)
    axios.defaults.headers.common.token = tokenTmp
    axios.defaults.headers.common.authtoken = 'reset'
          var initialState = {
            account: {
              login: '',
              name: '',
              firstname: '',
              mail: '',
              role: ['user'],
              emailKey: '',
              contracttype: '',
              isAgalan: null,
              infos: {
                user_status: {
                  name: 'Cluster acces status',
                  value: '',
                },
                expire_date: {
                  name: 'Expiration date',
                  value: '',
                },
                contract_date: {
                  name: 'Contract date',
                  value: '',
                },
                charter_date: {
                  name: 'Charter date',
                  value: '',
                },
              },
            },
            windowW: null,
            windowH: null,
            drawer: false,
            showDrawerBtn: false,

            // Booleens de status
            admin: false,
            reviewer: false,
            permanent: false,
            extern: false,
            uid: '',
            token: '',
            authtoken: 'reset2',

            role: ['user'],

            // Information de compte : a tirer du ldap

            boolAddMember: false,
            projectsMember: [
              {
                cn: 'pr-admin',
                description: 'Administration Play',
              },
            ],
            projectsAdmin: ['pr-admin', 'pr-funproject'],
            dialog: false,

            darkTheme: true,

            IMG_NEBULEUSE_out: 'https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/images/nebuleuse.jpg',
            imgNebuleuse: '@/assets/nebuleuse.jpg',
            colors: {
              card_bg_dark: '#27293d',
              card_bg_light: '',
            },
            joinedProjects: {
              title: 'Joined Projects Table',
              columns: ['Name', 'Description', 'Status', 'Laboratory', 'Wiki', 'Accounting'],
              projects: [],
            },
            createdProjects: {
              title: 'Created Projects Table',
              columns: ['Name', 'Description', 'Status', 'Laboratory', 'Wiki', 'Accounting'],
              projects: [],
            },
            projectsToReview: {
              title: 'Projects to review',
              columns: ['name', 'description', 'laboratory', 'technicalreview', 'scientificreview', 'reject'],
              projects: [],
             },
            mailingList: [ // Recuperer les uid des subscription dans this.$root.data.listMailingList
            ],
            listMachine: [],
            listPole: [],
            listLab: [],
            listFunctionalGroup: [],
            listMailingList: [],
            listReferent: [],
            allActiveProjects: [],
            allGroups: [],
            listPoleLeader: [],
            link: {
              accounting: 'https://perseus-front.univ-grenoble-alpes.fr/error/coming-soon', // 'https://ciment-grid.univ-grenoble-alpes.fr',
              documentation: 'https://gricad.gricad-pages.univ-grenoble-alpes.fr/user_doc/',
            },
            listAllPoles: [],
            listAllLabs: [],
            valuePole: false,
            menu1: 'pole',
            menu2: 'year',
            itemMenu2: [
              'year',
              '2020',
              '2019',
              '2018',
              '2017',
              '2016',
              '2015',
              '2014',
              '2013',
              '2012',
              '2011',
              '2010',
              '2009',
              '2008',
            ],
            valueYear: false,
            dialogCookies: true,
            my_ip: '',
            validToken: false,

            // Formulaire de creation de compte
            registrationForm: {
              login: null,
              firstname: null,
              lastname: null,
              lab: null,
              otherlab: null,
              contracttype: null,
              contractdate: null,
              contractdate_ts: null, // timestamp
              email: null,
              password: null, // sous forme de hash ?
              passwordcheck: null,
              cguboolean: false,
              permanent: false,
              uidNumber: '',
            },
            alertExpire: false,
            expiration: 30,
            updated: false,
            getProjectPole: false,
          }
            initialState.windowW = document.documentElement.clientWidth
            initialState.windowH = document.documentElement.clientHeight
          Object.keys(initialState).forEach(key => {
            store.state[key] = initialState[key]
          })
  }

export default resetStore
